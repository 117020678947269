/**
 * How Globalnav loads
 * - [verifyMgn] Verify _mgn state - Check to see if _mgn exists in the window already or in localStorage
 * - [fetchMgn] Fetch _mgn from /svc/session if no _mgn exists
 * - [prepareGlobalnav] Prepare globalnav for render - If globalnav isn't on the page, then grab Handlebars and globalnav's templates
 * - [renderGlobalnav] Render globalnav - apply _mgn and config passed through window.globalnavConfig to handlebars template and cache them
 * - [finalPrep] Final prep - once globalnav is ready, prep dropdowns, add gateway
 * Optionally set _mgn.fetch_user=true to force a user fetch and rerender if one is found
 */
(function() {
  function get(obj, path) {
    if (!obj) {
      return null;
    }
    return path.reduce(function(xs, x) {
      return xs && xs[x]
        ? xs[x]
        : null
    }, obj);
  }
  var unloading = false;
  var _mgn = window._mgn || {};
  var isHbsLoaded = false;
  var isMgnLoaded = false;
  var env = _mgn && _mgn.env;
  if (!env) {
    //let's assume production if no option is set
    env = window.globalnavConfig && window.globalnavConfig.env
    || window.location.hostname == 'staging.test.aiobits.com' ? 'staging' : 'production';
  }

  var getActiveDropdown = function() {
    return document.querySelector('.mgn-active');
  }

  var clickOutsideDropdown = function(e) {
    if(!getActiveDropdown()) {
      return;
    }

    // Close dropdown when clicking outside of it
    // IE11 doesn't support closest()
    if(e.target.closest && !e.target.closest('.mgn-active')) {
      closeDropdown();
    }

    setTimeout(function() {
      if(!unloading && e.target.tagName == 'A') {
        closeDropdown();
      }
    }, 100);
  };

  var toggleDropdown = function(e) {
    e.preventDefault();
    var parent = e.currentTarget;
    var name = parent.dataset.dropdown;
    var el = document.querySelector('.mgn-dropdown-' + name);
    if(!el) { return; }

    if(name == 'mobile') {
      document.querySelector('.mgn-icon-menu').classList.toggle('mgn-disabled');
      document.querySelector('.mgn-icon-close').classList.toggle('mgn-disabled');
    } else {
      el.style.right = dropdownPosition(parent, el) + '%';
    }

    var currentActiveDropdown = getActiveDropdown();
    if(currentActiveDropdown) {
      closeDropdown();
    }

    if(!currentActiveDropdown || currentActiveDropdown != el) {
      setTimeout(function() {
        el.classList.remove('mgn-disabled');
        el.classList.add('mgn-active');
        document.addEventListener('click', clickOutsideDropdown);
      }, 10);
    }
  };

  var closeDropdown = function() {
    var currentActiveDropdown = getActiveDropdown();
    if(currentActiveDropdown) {
      currentActiveDropdown.classList.remove('mgn-active');
      currentActiveDropdown.classList.add('mgn-disabled');
    }
    document.removeEventListener('click', clickOutsideDropdown);
  };

  var mobileAccordion = function() {
    var items = document.querySelectorAll('.mgn-drawer-mobile .mgn-item');
    for (var i=0; i<items.length; i++) {
      items[i].addEventListener('click', function(e) {
        e.currentTarget.classList.toggle('mgn-open')
        var opens = document.querySelectorAll('.mgn-drawer-mobile .mgn-item.mgn-open');
        for (var i=0; i<opens.length; i++) {
          if(opens[i] != e.currentTarget) {
            opens[i].classList.remove('mgn-open');
          }
        }
      });
    }
  }

  var dropdownPosition = function(parent, el) {
    var total = document.querySelector('.mgn-bar').offsetWidth;
    var right = total - parent.getBoundingClientRect().left - parent.offsetWidth;
    var padding = 7; // magic number, I know...
    return (right + padding) / total * 100;
  }

  var showSubnavLinks = function() {
    var subnavLinks = document.querySelectorAll('#header-nav .mgn-hide-local-managed');
    if (!isLocalManaged()) {
      for (var idx = 0; idx < subnavLinks.length; idx++) {
        subnavLinks[idx].classList.remove('mgn-hide-local-managed');
      }
    }
  }

  var updateCopyright = function() {
    var el = document.querySelector('#js-copyright-year');
    if(el) {
      var currentYear = new Date();
      el.innerHTML = currentYear.getFullYear();
    }
  };

  var includeGateway = function() {
    if(isLoggedIn() && !document.querySelector('#js-gateway-script')) {
      var a = document.createElement('link');
      a.type = 'text/css';
      a.rel = 'stylesheet';
      a.href = _mgn.urls.gateway_stylesheets;
      document.head.appendChild(a);

      var b = document.createElement('script');
      b.type = 'text/javascript';
      b.src = _mgn.urls.gateway_scripts;
      document.body.appendChild(b);
    }
  };

  var isLoggedIn = function() {
    return !!get(_mgn, ['user']);
  };

  var isPro = function() {
    return get(_mgn,
      [
        'user',
        'account',
        'products',
        'search',
        'status'
      ]
    ) === 'active';
  };

  var isLocal = function() {
    return get(_mgn, 
      [
        'user',
        'account',
        'products',
        'local',
        'status',
      ]
    ) === 'active';
  };

  var isLocalManaged = function() {
    return get(_mgn,
      [
        'user',
        'account_services',
        'local',
        'limits',
        'managed'
      ]
    ) === 1;
  }

  var isAccountAdmin = function() {
    if(!isLoggedIn()) {
      return false;
    }

    for (var i = 0; i < _mgn.user.accounts.length; i++) {
      var account = _mgn.user.accounts[i];

      // User owns an account with Pro
      if (account.owner_id == _mgn.user.id && account.products.indexOf('search') !== -1) {
        return true;
      }

      // User has "account access" to this account to make seat changes, etc.
      if (account.products.indexOf('account') !== -1) {
        return true;
      }
    }
    return false
  };

  var onReady = function(callback) {
    if(document.readyState != 'loading') {
      callback();
    } else {
      document.addEventListener('DOMContentLoaded', callback);
    }
  };

  /**
   * Once globalnav is rendered, we want to apply some event listeners
   * and style rules to clean it up.
   */
  var finalPrep = function() {
    var items = document.querySelectorAll('#globalnav [data-dropdown]');
    for (var i=0; i<items.length; i++) {
      items[i].addEventListener('click', toggleDropdown); // { once: true }
    }

    window.addEventListener('beforeunload', function() {
      unloading = true;
      return; // return here is a browser bug fix
    });

    var stylesheet = document.querySelector('.mgn-stylesheet').sheet;

    if(isLoggedIn()) {
      stylesheet.insertRule(".mgn-show-user { display: block !important; }");
      stylesheet.insertRule(".mgn-hide-user { display: none; }");
    }

    if(isPro()) {
      stylesheet.insertRule(".mgn-show-pro { display: block !important; }");
      stylesheet.insertRule(".mgn-hide-pro { display: none; }");
    }

    if(isLocal()) {
      stylesheet.insertRule(".mgn-show-local { display: block !important; }");
      stylesheet.insertRule(".mgn-hide-local { display: none; }");
    }

    if(isAccountAdmin()) {
      stylesheet.insertRule(".mgn-show-owner { display: block !important; }");
    }

    showSubnavLinks();
    updateCopyright();
    includeGateway();
    mobileAccordion();
  }

  /**
   * Render with handelbars once everything we need is added to page
   */
  var renderGlobalnav = function() {
    onReady(function() {
      if (!isMgnLoaded || !isHbsLoaded) {
        return;
      }
      var config = {};
      //merge mgn and config to render globalnav
      for (var key in _mgn) {
        config[key] = _mgn[key];
      }
      for (var key in window.globalnavConfig) {
        config[key] = window.globalnavConfig[key];
      }

      var container = document.querySelector('#globalnav');
      if(container) {
        var globalnav = document.createElement('div');
        globalnav.innerHTML = Handlebars.partials.globalnav(config);
        container.parentNode.replaceChild(globalnav, container);
      }

      var container = document.querySelector('#globalfooter');
      if(container && !container.hasChildNodes()) {
        var footer = document.createElement('div');
        footer.innerHTML = Handlebars.partials.globalfooter(config);
        container.appendChild(footer);
      }
      finalPrep();
    });
  }

  /**
   * Grab handlebars and the partials to render globalnav
   * @param {Function} callback once both are done, what to do next
   */
  var prepareGlobalnav = function (callback, rerender) {
    onReady(function() {
      if (!rerender && document.querySelector('#globalnav .mgn-bar')) {
        return finalPrep();
      }
      var loadGlobalnavClient = function() {
        if (get(window, ['Handlebars', 'globalnav'])) {
          return callback();
        }
        var gn = document.createElement('script');
        gn.src= "https://test.aiobits.com/globalnav/1.16.1/scripts/globalnav_client.hbs.js"
        gn.type="text/javascript";
        gn.onload = function() {
          isHbsLoaded = true;
          callback();
        };
        document.body.appendChild(gn);
      };
      if (window.Handlebars) {
        return loadGlobalnavClient();
      }
      var hbs = document.createElement('script');
      hbs.src="https://cdnjs.cloudflare.com/ajax/libs/handlebars.js/4.1.2/handlebars.js";
      hbs.type="text/javascript";
      hbs.onload = loadGlobalnavClient;
      document.body.appendChild(hbs);
    })
  }

  /**
   * Update the window object with the updated user config
   * @param {Object} mgn
   */
  var setMgn = function(mgn) {
    var mapToInt = function(value) {
      return value !== "0" ? 1 : 0;
    };

    _mgn.env = mgn.env ? mgn.env : 'production';
    _mgn.hosts = mgn.hosts ? mgn.hosts : {};
    _mgn.urls = mgn.urls ? mgn.urls : {};
    _mgn.metanav = mapToInt(mgn.metanav);

    if (mgn.user) {
      _mgn.user = {
        id: parseInt(mgn.user.id),
        display_name: mgn.user.display_name,
        email: mgn.user.email,
        avatar: mgn.user.avatar,
        full_name: mgn.user.full_name,
        mozpoints: mapToInt(mgn.user.mozpoints),
        premium: mapToInt(mgn.user.premium),
        admin: mapToInt(mgn.user.admin),
        verified: mapToInt(mgn.user.verified),
        local_push: mapToInt(mgn.user.local_push),
      };
      if (mgn.user.admin_id) {
        _mgn.user.admin_id = mgn.user.admin_id;
      }
      if (mgn.user.accounts) {
        _mgn.user.accounts = mgn.user.accounts;
      }
      if (mgn.user.account_services) {
        _mgn.user.account_services = mgn.user.account_services;
      }
      if (mgn.user.account) {
        _mgn.user.account = mgn.user.account;
      }
    }
    window._mgn = Object.assign(window._mgn || {}, _mgn);
    isMgnLoaded = true;
  }

  /**
   * Grab user config from /svc/session
   * @param {Function} callback
   */
  var fetchMgn = function (callback) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function() {
      var parsedMgn = {};
      try {
        parsedMgn = JSON.parse(this.responseText);
        if (!parsedMgn.success) {
          throw new Error('Invalid user state')
        }
        setMgn(parsedMgn);
        // Notify MTM of the user fetch
        document.dispatchEvent(new CustomEvent('mozuser'));
      } catch (e) {
        //don't proceed if invalid mgn.
        return;
      }
      //TODO: more elegant solution for caching
      // localStorage.setItem('globalnav_mgn', JSON.stringify(window._mgn));
      callback();
    });
    var sessionUrl = env === 'production'
      ? 'https://test.aiobits.com/svc/session'
      : 'https://staging.test.aiobits.com/svc/session';
    xhr.open('GET', sessionUrl);
    xhr.withCredentials = true;
    xhr.send(null);
  }

  /**
   * Check if _mgn has been loaded for the user previously
   * otherwise fetch it form the endpoint
   * @param {Function} callback
   */
  var verifyMgn = function(callback) {
    if (!(window._mgn && window._mgn.hosts)) {
      return fetchMgn(callback);
    }
    callback();
  }

  // fetch_user will re-render if logged in
  if(window._mgn.fetch_user) {
    fetchMgn(function() {
      if(window._mgn.user) {
        prepareGlobalnav(renderGlobalnav, true);
      } else {
        document.querySelector('.mgn-login').classList.remove('mgn-invisible');
        finalPrep();
      }
    })
  } else {
    verifyMgn(function() {
      prepareGlobalnav(renderGlobalnav)
    })
  }

  // Allows parent page to reload mgn and re-render
  window._mgn = window._mgn || {}
  window._mgn.reload = function() {
    fetchMgn(function() {
      return prepareGlobalnav(renderGlobalnav, true)
    }
  )};
})();
